import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { scroller } from "react-scroll";

//images
import Caret_down from "../../images/caret_down.svg"

// Animation 
import AppendGlobe from "../Animation/append-globe";


const MainBannerBlock = ({value}) => {

  const [isThemeG, setIsThemeG] = useState("");

  useEffect(() => {
    setIsThemeG(value)
  },[value])

  const scrollToSection = () => {
    scroller.scrollTo("case-studies-main", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
      <div className="container mx-auto h-full relative">
        <div className="flex h-full items-center">
          <div className=" w-full sm:w-7/10 md:w-3/5 lg:w-1/2 ">
            <div className="relative z-20 xl:z-10">
              <div className="w-full mx-auto md:mx-0 home-heading-banner-text font-poppings-bold text-h2 sm:text-h0 xl:text-h1 text-term-primary">
                <h1 className="pointer-events-none text-center sm:text-left">
                  <span className="text-cals2">
                    We Decode Global Digital Challenges &
                  </span>
                  <span className="text-cals2 text-cyan"> Generate Growth</span>
                </h1>
              </div>
              <p className="pointer-events-none md:ml-0 xl:mr-1-16 pt-4 pb-6-6 font-worksans-normal text-p2 text-term-primary text-center sm:text-left">
                From startups to Fortune 500 companies we offer every client
                creative solutions that increase growth. Experience our trusted
                approach, which is grounded is accountability, integrity and a
                passion for digital development.
              </p>
              <div className="flex flex-wrap justify-center sm:justify-start w-full">
                <div>
                  <Link
                    to="/get-in-touch"
                    className="py-2-2 px-3-1 bg-cyan-lite font-poppings-bold inline-block text-cyan text-rh5 md:text-rp3 lg:text-h5 0 shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
                  >
                    Connect With Us
                  </Link>
                </div>
                <div className="ml-6 lg:ml-38">
                  <button
                    className="p-3 items-center flex cursor-pointer"
                    onClick={scrollToSection}
                  >
                    <h6 className="mr-2-2 block text-term-primary font-poppings-bold text-h6">
                      Our Work
                    </h6>
                    <svg
                      width={10}
                      height={5}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m0 0 5 5 5-5H0Z"
                        fill="var(--bg-background-septenary)"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-1/2 right-1/2 sm:top-0 sm:left-auto sm:right-0 -mr-20 z-0 lg:z-10 cstm-glob-responsive">
          <AppendGlobe isTheme={isThemeG} />
        </div>
      </div>
    </>
  )
}

export default MainBannerBlock