import React,{useState} from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image"

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import LiveChat from "../components/Home/livechat";
import HonorsAchievements from "../components/Home/honors-achievements";
import MainBannerBlock from "../components/Home/main-banner-block"



const DecodeupGlobePresents = (props) =>
{
  const [isThemeMB, setIsThemeMB] = useState("");
  const { data } = props

  const SEOdata = data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = data.pageData.edges[0].node.frontmatter?.Hreflang

  const sendStateToLayout = (isThemefromLayout) => {
    setIsThemeMB(isThemefromLayout)
  }
 
  return (
    <Layout sendStateToLayout={sendStateToLayout}>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "decodeup-glob-presents"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        
      />

      <div className="blob-absolute absolute w-full h-screen min-h-860 max-h-900 z-1-1">
        <div className="blob blob--one"></div>
        <div className="blob blob--two"></div>
        <div className="blob blob--three"></div>
        <div className="blob blob--four"></div>
      </div>
      <div className="mt-10 relative blog-bg-blur h-screen min-h-712 lg:min-h-860 max-h-900 overflow-x-hidden overflow-y-hidden">
        <div className="h-full">
          <MainBannerBlock value={isThemeMB} />
        </div>
      </div>


      {/* honors and achivements section */}
      <HonorsAchievements />

      {/*schedule a free cosultation chat section */}
      <LiveChat />
    </Layout>
  )
}

export default DecodeupGlobePresents;

export const pageQuery = graphql`
query {
  pageData: allMarkdownRemark(
   filter: { fileAbsolutePath: { regex: "/pages/decodeup-glob-presents.md/" } }
 ) {
   edges {
     node {
       frontmatter {
         seo_metadata {
           seo_title
           seo_keyword
           seo_description
           image_format
           feature_image_height
           img_width
           facebook_url
           twitter_page_username
           twitter_image_alt_tag
           twitter_estimated_reading_lable
           twitter_estimated_reading_time
           page_publish_date
           page_last_modified_time
           seo_Image {
             childImageSharp {
               gatsbyImageData(layout: FIXED, width: 1200)
             }
           }
           seo_Single_Image
         }
         Schema {
           sitelinks_schema
           breadcrumb_schema
           logo_schema
           organization_schema
           aggregate_rating_schema
           local_business_schema
           site_navigation_schema
           speakable_specification_schema
         }
         Hreflang {
           hreflang_1
           hreflang_2
           hreflang_3
           hreflang_4
         }
       }
     }
   }
 }
}
`;











